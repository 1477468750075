import React from "react";
import { SEO, PageLayout, Image, FaqList } from "@bluefin/components";
import { Grid, Header } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class FaqPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout hero={false} subfooter={false} className={"faq-page"}>
          <Image
            src={getComponentFiles({
              components: fishermanBusinessWebsitePage.components,
              componentName: "HeroElement",
              numToSelect: 1,
            })}
            className={"page-background-image"}
            background={true}
          >
            <div />
          </Image>
          <Grid className={"faq-content-container"} stackable={true}>
            <Grid.Column width={7}>
              <Header
                as={"h1"}
                className={"faq-header"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "faqs_header",
                  defaultValue: "Faqs",
                })}
              />
              <FaqList
                faqs={[
                  {
                    question: "Is it safe to get a tattoo at your parlor?",
                    answer:
                      "Yes, it is safe to get a tattoo at our parlor. We follow strict sanitation and hygiene protocols to ensure the safety and well-being of our clients. All of our equipment is sterilized and single-use, and our artists wear gloves and other protective gear during the tattooing process. We take your health and safety seriously and strive to create a clean and comfortable environment for all of our clients.",
                  },
                  {
                    question: "How do I choose the right design for my tattoo?",
                    answer:
                      "Choosing the right design for your tattoo can be a difficult decision, but our artists are here to help. We offer consultations to discuss your ideas, preferences, and any concerns you may have. Our artists can provide recommendations and work with you to create a custom design that is unique and meaningful to you. We also have a portfolio of previous work that can help give you inspiration and ideas for your tattoo.",
                  },
                  {
                    question: "How do I take care of my new tattoo?",
                    answer:
                      "After getting a tattoo, it is important to take proper care of it to ensure proper healing and minimize the risk of infection. We provide all of our clients with aftercare instructions, which include keeping the area clean and dry, avoiding direct sunlight, and applying a moisturizer or ointment as directed. We also recommend avoiding swimming, saunas, and other activities that can expose the tattoo to excessive moisture or bacteria. Our aftercare instructions are designed to help your tattoo heal quickly and beautifully. If you have any questions or concerns during the healing process, our artists are always available to provide guidance and support.",
                  },
                ]}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(pageType: { eq: "FAQ" }) {
      title
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        contentNodes {
          content
        }
      }
    }
  }
`;
